import React, { useState, useEffect } from 'react';
import axios from 'axios';
import OneDonation from '../onedonation/OneDonation';
import PopupCart from '../component/PopupCart';
import VerificationGallery from '../verificat/VerificationGallery';




const Homepage = () => {
    const [products, setProducts] = useState([]);



    useEffect(() => {
        const accessToken = process.env.REACT_APP_API_TOKEN;


        axios
            .get('https://back.frigidercomunitar.md/home/cos/', {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
            .then(response => {
                setProducts(response.data);
            })
            .catch(error => {
                console.error('Ошибка при получении категорий:', error);
            });

    }, []);


    return (
        <div className="test_block">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <h3 className="main-content__main-products desktop-title">  Все продукты, купленные на нашем сайте попадают в руки нуждающихся.</h3>
                        <div className="desktop-content">
                            <p>Все продукты, купленные на нашем сайте попадают в руки нуждающихся.
                                Мы помогаем одиноким пожилым людям, инвалидам, пенсионерам, ветеранам ВОВ, одиноким матерям, многодетным семьям, нетранспортабельным людям, бедным и просто голодным людям, которые в данный момент и день оказались в сложной жизненной ситуации.</p>
                        </div>
                    </div>
                </div>
                <div className="row block_center">
                    {Array.isArray(products) && products.length > 0 && products.map(product => (
                        <div key={product.id} className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                            <div className="main-content__product product-box">
                                <div className="">
                                    <div className="main-content__product__image" itemprop="image">
                                        <img src={product.image} alt="" />
                                    </div>
                                    <p className="main-content__product__name" itemprop="name">{product.title}</p>
                                    <p className="main-content__product__description">{product.detail}</p>
                                </div>
                                <div className="main-content__product__to-cart-box" itemprop="offers" >
                                    <span className="main-content__product__price"><span className="product-total-amount" itemprop="price">{product.count}</span> <span></span>MDL</span>
                                    
                                    <PopupCart title={product.title} count={product.count} />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>      
            <OneDonation />
            <VerificationGallery />



        </div>
    )
}

export default Homepage;
