import React, { useEffect } from 'react';

const RedirectPaynet = ({ paymentData }) => {
  useEffect(() => {
    if (paymentData) {
      const form = document.createElement('form');
      form.method = 'POST';
      form.action = 'https://paynet.md/acquiring/getecom';

      const fields = [
        { name: 'operation', value: paymentData.PaymentId },
        { name: 'ExpiryDate', value: paymentData.ExpiryDate },
        { name: 'Signature', value: paymentData.Signature },
        { name: 'LinkUrlSucces', value: 'https://frigidercomunitar.md/' },
        { name: 'LinkUrlCancel', value: 'https://frigidercomunitar.md/' },
        { name: 'Lang', value: 'ru' },
      ];

      fields.forEach(({ name, value }) => {
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = name;
        input.value = value;
        form.appendChild(input);
      });

      document.body.appendChild(form);
      form.submit();
    }
  }, [paymentData]);

  return null; // Компонент ничего не рендерит
};

export default RedirectPaynet;
