import React, { useState } from "react";
import cert from '../../Assets/images/cert.png';
import very from '../../Assets/images/very.png';

const VerificationGallery = () => {
    const [selectedImage, setSelectedImage] = useState(cert); // Устанавливаем начальное изображение

    const images = [
        { id: 1, thumbnail: cert, large: cert },
        { id: 2, thumbnail: very, large: very },
    ];

    const handleImageChange = (largeImage) => {
        setSelectedImage(largeImage);
    };

    return (
        <div className="container">
            <div className="row block_center">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <h3 class="main-content__main-products desktop-title tac">Сертификат о валидации</h3>
                    <div class="desktop-content tac no_decor"><p><strong>Frigider Comunitar</strong> прошла независимый аудит и получил сертификацию международной благотворительной платформы  <a className="colora" href="https://validation.cafamerica.org/group/261556"><strong>CAF America</strong></a>.</p></div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="verification-gallery">
            
            <div className="large-image-container">
                <a href="https://validation.cafamerica.org/f9290800-01cb-46bb-9bf9-3bfce94dbeb1#acc.hKRsJ67x"><img
                    src={selectedImage}
                    alt="Verification Certificate"
                    className="large-image"
                /></a>
            </div>

            {/* Миниатюры */}
            <div className="thumbnail-container">
                {images.map((img, index) => (
                    <img
                        key={index}
                        src={img.thumbnail}
                        alt={`Thumbnail ${index + 1}`}
                        className={`thumbnail ${selectedImage === img.large ? "active" : ""}`}
                        onClick={() => handleImageChange(img.large)}
                    />
                ))}
            </div>
        </div>
                </div>
            </div>
            </div>
        
    );
};

export default VerificationGallery;
